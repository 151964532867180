import React from 'react'
import { useSpring, animated } from 'react-spring'

import Layout from '../components/layout'
import { FitKidzPricingMarketing } from '../components/marketing/fit-kidz-pricing'

export const FitKidzPricing = () => {
  const springOpacity = useSpring({
    from: { opacity: 0, transform: 'translateX(90px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  })
  return (
    <Layout seoTitle="Fit Kidz 4 Fun Pricing">
      <animated.div style={springOpacity}>
        <FitKidzPricingMarketing />
      </animated.div>
    </Layout>
  )
}

export default FitKidzPricing
